import { useData } from './DataProvider';
import Typing from './Typing';

type Props = {
texts?: string[],
title?: string
}

const Hero = ({texts, title}: Props) => {

    const { language } = useData();

    const text = language ?
    [
        "أفضل تجربة عرض",
        "لأفضل تجربة مؤتمرات",
        "لأفضل إدارة للحدث",
    ] : [
        "for best exhibition experience",
        "for best conferences experience",
        "for best event management",
    ]
    const titlePlaceholder=language ? "لمسه الابتكارنحن تبتكر وانت تحتفل" : "Touch innovation"

    return (
        <section className='snap-start bg-transparent'>
            <div className={`h-screen bg-transparent flex flex-col justify-center relative  items-center ${language ? "md:items-end text-right" : "md:items-start text-left"} max-w-7xl px-10 mx-auto `}>
                <span className='text-5xl m:text-7xl font-bold text-white max-w-xl relative z-20  my-gradient-text'>
                    {title}
                    {!texts && titlePlaceholder}
                    &nbsp;<Typing words={texts || text} />
                </span>
            </div>
        </section>
    )
}

export default Hero
import { motion } from 'framer-motion'
import { useData } from './DataProvider';
import { MemberData } from '../../typing';



const Member = ({ image, name, role, social }: MemberData) => {

    const { language } = useData();


    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ default: { duration: 0.5, ease: "easeInOut" } }}
            viewport={{ once: true, amount: 0.4 }}
            className='relative group cursor-pointer overflow-hidden rounded-xl h-[28rem] min-w-[20rem] max-w-lg transition-all shadow-2xl'>
            <div className={`absolute w-full bottom-0 flex flex-col py-3 px-3 bg-gradient-to-t from-white ${language ? 'text-right' : 'text-left'}`}>
                <h1 className='capitalize text-xl font-bold text-gray-700 dark:text-gray-600 group-hover:underline py-2'>{name}</h1>
                <p className='capitalize text-md font-light text-gray-700 dark:text-gray-600'>{role}</p>
            </div>
            <img src={image!} alt="member pic" className=' h-full w-full object-cover object-top ' />

            <motion.div
                initial={{ opacity: 0, x: language ? -20 : 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: false, amount: 0.1 }}
                className={`flex absolute md:hidden md:group-hover:flex  flex-col space-y-5 md:space-y-2 items-center justify-center top-12 md:top-2 ${language ? "left-2" : "right-2"} p-3 glass rounded-xl shadow-xl `}>
                {/* facebook */}
                <a href={social?.facebook || "#"} className="text-gray-400 dark:text-gray-200 hover:scale-110 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                    <svg width="15" height="15" fill="currentColor" className="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                        </path>
                    </svg>
                </a>
                {/* twitter */}
                <a href={social?.twitter || "#"} className="text-gray-400 dark:text-gray-200 hover:scale-110 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                    <svg width="15" height="15" fill="currentColor" className="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                        </path>
                    </svg>
                </a>
                {/* linked in */}
                <a href={social?.linkedIn || "#"} className="text-gray-400 dark:text-gray-200 hover:scale-110 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                    <svg width="15" height="15" fill="currentColor" className="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                        </path>
                    </svg>
                </a>
                {/* instagram */}
                <a href={social?.instagram || "#"} className="text-gray-400 dark:text-gray-200 hover:scale-110 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">

                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 512 512"><path d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z" /><path d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z" /></svg>
                </a>
            </motion.div>
        </motion.div>
    )
}

export default Member
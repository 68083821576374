import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { slideLeft, slideRight } from './slide'
import Card from './Card'
import { useData } from './DataProvider'

type Props = {
    Bg: boolean
}
const ServicesSection = ({ Bg }: Props) => {

    const [Hover, setHover] = useState<boolean>(false)
    const { services } = useData()

    return (
        <div className={`w-screen h-screen flex items-center transition-all duration-1000 transform-gpu  ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
            <div
                id='slider'
                className="w-full flex space-x-10 overflow-x-scroll hide-scrollbar pl-40 pr-5 py-20 scroll-smooth transition-all"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {
                    services?.map((service, index) => (
                        <Card
                            key={index}
                            image={service?.image}
                            text={service?.description}
                            title={service?.title} />
                    ))
                }
            </div>
            <div
                className={`w-full absolute px-20 transform transition-all duration-300 hidden md:flex justify-between z-40 ${Hover ? " md:pacity-100 " : " opacity-0 "}`}>
                <ChevronLeftIcon onClick={() => slideLeft("slider")} className='w-12 p-2 rounded-full bg-white shadow-xl text-gray-800 ' />
                <ChevronRightIcon onClick={() => slideRight("slider")} className='w-12 p-2 rounded-full bg-white shadow-xl text-gray-800 ' />
            </div>
        </div>
    )
}

export default ServicesSection
import { Navigations, Project, Service, Team } from '../typing';

export const navigations: Navigations = {
   arabic: [
        {
            title: "الصفحة الرئيسية",
            link: "/",
        },
        {
            title: "من نحن",
            link: "/about",
        },
        {
            title: "خدماتنا",
            link: "/services",
        },
        {
            title: "مَلَفّ",
            link: "/works",
        },
        {
            title: "اتصل بنا",
            link: "/contact",
        },
    ],
    english: [
        {
            title: "Home",
            link: "/",
        },
        {
            title: "Who we are",
            link: "/about",
        },
        {
            title: "Our services",
            link: "/services",
        },
        {
            title: "Portfolio",
            link: "works",
        },
        {
            title: "Contact us",
            link: "/contact",
        },
    ],
}

export const services: Service[] = [
    {
        id: 1,
        image: "https://th.bing.com/th/id/R.2f01f829e893e6c28f86c2384106385f?rik=BvaxJqgDze50EQ&riu=http%3a%2f%2fpastorjess.com%2fuploads%2f8%2f0%2f0%2f0%2f80007388%2fshutterstock-300283754_1_orig.jpg&ehk=5giW06fvkNlGxm17d4NjX3G4Se0HWuJOmOnwNEq%2fwrE%3d&risl=&pid=ImgRaw&r=0",
        title: "Testing service card",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, ratione dignissimos. Illo deleniti impedit veritatis, obcaecati ex libero perspiciatis eligendi sequi sunt optio, voluptatibus ullam ipsum ipsam dolores perferendis inventore."
    },
    {
        id: 2,
        image: "https://th.bing.com/th/id/OIP.-mWrkIEjcW_kWol_uDhasQHaEU?w=320&h=186&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        title: "Testing service card",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, ratione dignissimos. Illo deleniti impedit veritatis, obcaecati ex libero perspiciatis eligendi sequi sunt optio, voluptatibus ullam ipsum ipsam dolores perferendis inventore."
    },
    {
        id: 3,
        image: "https://th.bing.com/th/id/R.2f01f829e893e6c28f86c2384106385f?rik=BvaxJqgDze50EQ&riu=http%3a%2f%2fpastorjess.com%2fuploads%2f8%2f0%2f0%2f0%2f80007388%2fshutterstock-300283754_1_orig.jpg&ehk=5giW06fvkNlGxm17d4NjX3G4Se0HWuJOmOnwNEq%2fwrE%3d&risl=&pid=ImgRaw&r=0",
        title: "Testing service card",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, ratione dignissimos. Illo deleniti impedit veritatis, obcaecati ex libero perspiciatis eligendi sequi sunt optio, voluptatibus ullam ipsum ipsam dolores perferendis inventore."
    },
    {
        id: 4,
        image: "https://th.bing.com/th/id/OIP.-mWrkIEjcW_kWol_uDhasQHaEU?w=320&h=186&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        title: "Testing service card",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, ratione dignissimos. Illo deleniti impedit veritatis, obcaecati ex libero perspiciatis eligendi sequi sunt optio, voluptatibus ullam ipsum ipsam dolores perferendis inventore."
    },
    {
        id: 5,
        image: "https://th.bing.com/th/id/R.2f01f829e893e6c28f86c2384106385f?rik=BvaxJqgDze50EQ&riu=http%3a%2f%2fpastorjess.com%2fuploads%2f8%2f0%2f0%2f0%2f80007388%2fshutterstock-300283754_1_orig.jpg&ehk=5giW06fvkNlGxm17d4NjX3G4Se0HWuJOmOnwNEq%2fwrE%3d&risl=&pid=ImgRaw&r=0",
        title: "Testing service card",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, ratione dignissimos. Illo deleniti impedit veritatis, obcaecati ex libero perspiciatis eligendi sequi sunt optio, voluptatibus ullam ipsum ipsam dolores perferendis inventore."
    },
    {
        id: 6,
        image: "https://th.bing.com/th/id/OIP.-mWrkIEjcW_kWol_uDhasQHaEU?w=320&h=186&c=7&r=0&o=5&dpr=1.3&pid=1.7",
        title: "Testing service card",
        description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, ratione dignissimos. Illo deleniti impedit veritatis, obcaecati ex libero perspiciatis eligendi sequi sunt optio, voluptatibus ullam ipsum ipsam dolores perferendis inventore."
    },


]


export const team: Team = {
    arabic: [
        {
            id: 1,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 2,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 3,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 4,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 5,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 6,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        }
    ], 
    english: [
        {
            id: 1,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 2,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 3,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 4,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 5,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        },
        {
            id: 6,
            image: "https://donya.live/admin/uploads/20230302095757_ceo.jpeg",
            name: ["name here", "arabic name here"],
            role: ["role english", "role Arabic"],
            social: {
                facebook: "facebook.com",
                twitter: "twitter.com",
                linkedIn: "linkedin.com",
                instagram: "instagram.com",
            }
    
        }
    ]
}

export const projects: Project[] = [
{
    id: 1,
    title: "Project title",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
{
    id: 2,
    title: "Project title2",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
{
    id: 3,
    title: "Project title3",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
{
    id: 4,
    title: "Project title4",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
{
    id: 5,
    title: "Project title5",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
{
    id: 6,
    title: "Project title6",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
{
    id: 7,
    title: "Project title7",
    client: "the client",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda",
    source: "https://images.unsplash.com/photo-1526925539332-aa3b66e35444?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvZGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
},
];
import { useEffect, useRef, useState } from 'react'
import { useData } from '../components/DataProvider';
import { animateScroll } from 'react-scroll';
import Clients from '../components/Clients';
import Footer from '../components/Footer';
import GlassCard from '../components/GlassCard';
import Tilt from '../components/Tilt';
import Card from '../components/Card';
import Typing from '../components/Typing';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Tv from '../components/Tv';
import ServicesSection from '../components/Services';
import Contact from '../components/Contact';

const About = () => {
    const [Bg, setBg] = useState(true)
    const [scroll, setScroll] = useState(0)

    const divRef = useRef<HTMLDivElement>(null);

    const { language, info } = useData();

    const handleScroll = () => {
        if (divRef.current) {
            const scrollTop = divRef.current.scrollTop;
            setBg(((scrollTop > 700 && scrollTop < 800) || scrollTop > 2000) && (scrollTop < 2300 || (scrollTop > 4300 && scrollTop < 5200 ? true : false)));
            setScroll(scrollTop);
            return
        }
    }

    useEffect(() => {
        let timer: any = null;

        function handleScroll() {
            if (timer !== null) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                animateScroll.scrollTo(window.pageYOffset);
            }, 200);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const text = language ?
        [
            "أفضل تجربة عرض",
            "لأفضل تجربة مؤتمرات",
            "لأفضل إدارة للحدث",
        ] : [
            "for best exhibition experience",
            "for best conferences experience",
            "for best event management",
        ];

    const slogan = language ? ['"نصنعك تحتفل"'] : ['"We create you celebrate"'];

    const axes = language ? ["أمة طموحة", "اقتصاد مزدهر", "مجتمع نابض بالحياة"] : ["An ambitious nation", "Thriving economy", "Vibrant society"];


    return (
        <main>
            <div
                ref={divRef}
                onScroll={handleScroll}
                className="fixed h-screen top-0 bottom-0 right-0 left-0 transition-all duration-200 scroll-smooth w-screen scrollbar-hide overflow-y-scroll overflow-x-hidden md:snap-y md:snap-mandatory overflow-scroll z-20 bg-transparent">
                <Header Bg={Bg} scroll={scroll} />

                <Hero
                    texts={text}
                    title={info?.title}
                />

                <section className='snap-center h-screen top-0' id=''>
                    <div className={`w-screen h-screen transition-all duration-1000 transform-gpu ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <div className={`h-screen  flex flex-col gap-5 justify-center pt-10 md:pt-0 ${language ? "md:flex-row-reverse text-right" : "md:flex-row text-left"} md:justify-between items-center relative  max-w-6xl px-10 mx-auto`}>
                            <div className={`w-full flex flex-col  space-y-10 relative  justify-center ${language ? "items-end" : "items-start"}`}>
                                <div className={`uppercase font-normal text-lg flex items-center space-x-3 ${language ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                    <div className={`h-[2px] w-7 ${Bg ? "bg-white" : "bg-black"}`} />
                                    <h1>{language ? "معلومات عنا" : "About us"}</h1>
                                </div>
                                <h1 className='text-3xl md:text-5xl font-bold max-w-xl my-gradient-text'>
                                    {info?.title} &nbsp;
                                    <Typing words={text} />
                                </h1>

                                <p className='max-w-xl'>
                                    {info?.description}
                                </p>
                            </div>
                                <Card image={info?.descriptionImage || ""} />
                        </div>
                    </div>
                </section>


                <section className='snap-center h-screen top-0' id=''>
                    <div className={`w-screen h-screen transition-all duration-1000 transform-gpu ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <div className={`h-screen flex flex-col  md:-space-x-10 justify-center items-center relative ${language ? "text-right md:flex-row-reverse space-x-reverse" : "text-left md:flex-row"} max-w-7xl px-10 mx-auto`}>
                            <div className="w-full max-w-xl relative z-10">
                                <Tilt>
                                    <GlassCard bg={Bg}>
                                        <div className='w-full flex flex-col  space-y-10 relative  justify-center max-w-lg'>
                                            <div className={`uppercase font-normal text-lg flex items-center space-x-3 ${language ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                                <div className={`h-[2px] w-7 ${Bg ? "bg-white" : "bg-black"}`} /> <h1>{language ? "مهمتنا" : "Our Mission"}</h1>
                                            </div>
                                            <h1 className='text-4xl font-bold max-w-xl  my-gradient-text'>
                                                {info?.title} &nbsp;
                                                <Typing words={text} />
                                            </h1>

                                            <p className='max-w-lg'>{info?.mission || ""}
                                                &nbsp;<b className='my-gradient-text'>
                                                    <Typing words={slogan} />
                                                </b>
                                            </p>
                                        </div>
                                    </GlassCard>
                                </Tilt>
                            </div>
                            <img
                                src={info?.missionImage || ""}
                                className='max-w-lg object-cover hidden md:flex'
                                alt="" />
                        </div>
                    </div>
                </section>


                <section className='snap-center h-screen top-0' id=''>
                    <div className={`w-screen h-screen transition-all duration-1000 transform-gpu ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <div className={`h-screen flex flex-col md:-space-x-10 justify-center items-center relative ${language ? "text-right md:flex-row-reverse space-x-reverse" : "text-left md:flex-row"} max-w-7xl px-10 mx-auto`}>
                            <div className="w-full max-w-xl relative z-10">
                                <Tilt>
                                    <GlassCard bg={Bg}>
                                        <div className='w-full flex flex-col  space-y-10 relative  justify-center max-w-lg'>
                                            <div className={`uppercase font-normal text-lg flex items-center space-x-3 ${language ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                                <div className={`h-[2px] w-7 ${Bg ? "bg-white" : "bg-black"}`} /> <h1>{language ? "رؤيتنا" : "Our Vsion"}</h1>
                                            </div>
                                            <h1 className='text-4xl font-bold max-w-xl  my-gradient-text'>
                                                {info?.title} &nbsp;
                                                <Typing words={text} />
                                            </h1>
                                            <p className='max-w-lg'>{info?.vision}<br />
                                                <b className='text-2xl font-bold max-w-xl  my-gradient-text'>
                                                    <Typing words={axes} />
                                                </b></p>
                                        </div>
                                    </GlassCard>
                                </Tilt>
                            </div>
                            <img
                                src={info?.visionImage || ""}
                                className='max-w-lg object-cover hidden md:flex'
                                alt="" />
                        </div>
                    </div>
                </section>

                <section className='snap-center h-screen top-0' id=''>
                    <div className={`w-screen h-screen flex items-center transition-all duration-1000 transform-gpu  ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <Tv source='https://www.srmg.com/transformation/videos/Sequence12.webm' />
                    </div>
                </section>

                <section className='snap-center h-screen top-0' id=''>
                    <div className={`w-screen h-screen flex items-center transition-all duration-1000 transform-gpu  ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <Clients scroll={scroll} />
                    </div>
                </section>

                <section className='snap-center md:h-screen top-0'>
                    <Contact />
                </section>

                <section className='snap-center h-screen top-0' id=''>
                    <ServicesSection Bg={Bg} />
                </section>

                <section className='snap-center h-auto top-0' id=''>
                    <div className={`w-screen h-auto flex items-center transition-all duration-1000 transform-gpu group ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <Footer />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default About
import { useState } from 'react'
import Pointer from './Pointer'
import { motion } from "framer-motion";
import Navigation from './Navigation';
import { useData } from './DataProvider';
import logo from "../assets/logo3.png";

type Props = {
  Bg?: boolean;
  scroll?: number
}

const Header = ({ Bg, scroll }: Props) => {

  const [hover, setHover] = useState(false)
  const [showNav, setShowNav] = useState<boolean>(false)

  const { language, setLanguage, info } = useData();

  const transition = { duration: 2, yoyo: Infinity, ease: "easeInOut" }

  return (
    <>
      <Pointer />
      <video
        autoPlay
        loop
        muted
        className='w-screen h-screen object-cover fixed '>
        <source src="https://touch.sa/assets/globe.mp4" type="video/mp4" />
        Your browser does not support the video tag.  
      </video>

      <span className={`text-3xl fixed top-5 ${language ? "right-5 md:right-10 " : "left-5 md:left-10"} md:top-7 transition-colors duration-300 font-black z-50 text-center `}>
        <img
          src={info.logo || ""}
          width={100}
          height={100}
          className='w-20 '
          alt='logo'
        />
      </span>

      <span
        className={`fixed z-50 top-5 ${!language ? "right-5 md:right-10" : "md:left-10 left-5 flex-row-reverse space-x-reverse"} md:top-10 flex space-x-5 items-center ${!Bg ? scroll! < 700 ? "text-white" : "text-black" : "text-white"}`}>
        <span
          onClick={() => setLanguage(!language)}
        >{language ? "En" : "Ar"}</span>
        <svg xmlns="http://www.w3.org/2000/svg"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setShowNav(!showNav)}
          fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={` w-10 transform-gpu transition-all  duration-300`}>
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={transition}
            strokeLinecap="round"
            strokeLinejoin="round"
            d={hover ? "M3.75 9h16.5m-16.5 6.75h16.5" : "M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"}
          />
        </svg>
      </span>

      <Navigation status={showNav} />
    </>
  )
}

export default Header
import React, { ReactNode, useEffect, useState } from 'react'

type Props = {
children: ReactNode;
}

const Tilt = ({children} : Props) => {

    const [id, setId] = useState<string>("");

    function generateAlphaNumeric(length: number): string {

        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            result += chars.charAt(randomIndex);
        }

        return result;
    }

    useEffect(() => {
        setId(generateAlphaNumeric(10))
    }, [])
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const VanillaTilt = require('vanilla-tilt');
            const tiltElement = document.getElementById(`${id}`) as HTMLElement;

            if (tiltElement) {
                VanillaTilt.init(tiltElement, {
                    max: 25,
                    speed: 400,
                    glare: true,
                    'max-glare': 0.5,
                });

                tiltElement.addEventListener('mouseover', () => {
                    VanillaTilt.init(tiltElement, {
                        max: 30,
                    });
                });

                tiltElement.addEventListener('mouseout', () => {
                    VanillaTilt.init(tiltElement, {
                        max: 25,
                    });
                });
            }
        }
    }, [id]);


  return (
    <div id={id} className="w-auto h-auto rounded-xl">
        {children}
    </div>
  )
}

export default Tilt
import { createContext, useContext, useEffect, useState } from "react";
import { fetchData } from "./FetchData";
import { Data, Info, MemberData, Project, Service } from "../../typing";


type DataContextType = {
    language?: boolean;
    setLanguage: (value: boolean) => void;
    data?: Data;
    setData?: (value: Data) => void;
    choose: (english: string, arabic: string) => string;
    info: Info;
    services: Service[];
    works: Project[];
    team: MemberData[];
    clients: string[]
};

const DataContext = createContext<DataContextType>({
    language: undefined,
    setLanguage: () => { },
    data: {},
    setData: () => { },
    choose: () => "",
    info: {},
    services: [],
    works: [], 
    team: [],
    clients: []
});

type DataProviderProps = {
    children: React.ReactNode;
};

export const DataProvider = ({ children }: DataProviderProps) => {
    const [language, setLanguage] = useState<boolean>(false);
    const [data, setData] = useState<Data>({});
    const [info, setInfo] = useState<Info>({});
    const [services, setServices] = useState<Service[]>([]);
    const [works, setWorks] = useState<Project[]>([]);
    const [team, setTeam] = useState<MemberData[]>([]);
    const [clients, setClients] = useState<string[]>([]);


    const getData = async () => {
        const response = await fetchData();
        const result: Data = response;
        setInfo(language ? result?.info![0] : result?.info![1])
        setServices(language ? result?.services![0] : result?.services![1])
        setWorks(language ? result?.works![0] : result?.works![1])
        setTeam(language ? result?.team![0] : result?.team![1])
        setClients(result?.clients || [])
        setData(result);
    }

    useEffect(() => {
        const savelanguage: { active: boolean } = JSON.parse(localStorage.getItem("language") || '{"active":false}');
        setLanguage(savelanguage.active);
        getData();
    }, []);

    const choose = (english: string, arabic: string) => language ? arabic : english;

  

    useEffect(() => {
        if(data?.info){
            setInfo(language ? data?.info![0] : data?.info![1])
        }
        if(data?.services){
            setServices(language ? data?.services![0] : data?.services![1])
        }
        if(data?.works){
            setWorks(language ? data?.works![0] : data?.works![1])
        }
        if(data?.team){
            setTeam(language ? data?.team![0] : data?.team![1])
        }
        localStorage.setItem("language", JSON.stringify({ active: language }));
    }, [language]);


    return (
        <DataContext.Provider value={{ language, setLanguage, data, setData, choose, info, services, works, team,clients }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = (): DataContextType => useContext(DataContext);

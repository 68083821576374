import React, { useState, useEffect } from "react";

const Pointer = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const [size, setSize] = useState(1);

  useEffect(() => {
    const setFromEvent = (e: MouseEvent) => {
      setPosition({ x: e.clientX, y: e.clientY });
      const target = e.target as HTMLElement;
      setIsHovering(
        target.tagName === "SPAN" ||
        (target.parentElement?.tagName === "span" || target.tagName === "svg" || target.parentElement?.tagName === "svg" || target.tagName === "path" && target.style.backgroundColor === "white")
      );
    };

    document.addEventListener("mousemove", setFromEvent);

    return () => {
      document.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  useEffect(() => {
    setSize(isHovering ? 60 : 30);
  }, [isHovering]);

  const style: React.CSSProperties = {
    position: "fixed",
    top: position.y,
    left: position.x,
    transition:
      "width 0.2s ease-in-out, height 0.2s ease-in-out, transform ease, filter 0.5s ease-in-out",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    height: `${size}px`,
    width: `${size}px`,
    zIndex: 200000,
    opacity: 1,
    pointerEvents: "none",
    mixBlendMode: "difference",
  };

  return (
    <div
      style={style}
      className="bg-white hidden md:flex "
    ></div>
  );
};

export default Pointer;

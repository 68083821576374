import { useState, useEffect } from 'react'
import { useData } from './DataProvider';
import { Project } from '../../typing';

const Works = () => {

    const [index, setIndex] = useState<number>(0)
    const { language, works } = useData();
    const [active, setActive] = useState<Project | null>();

    useEffect(() => {
        setActive(works[index]);
    }, [index, works])

    const handleChangeActive = (increment: boolean) => {

        if (index === 0 && increment === false) return;

        if (index + 1 === works.length && increment === true) return;

        return increment ? setIndex(index + 1) : setIndex(index - 1);

    }




    return (
        <div className="max-w-6xl px-2 py-10 mx-auto">

            <h1 className="mt-2 text-2xl font-semibold capitalize lg:text-3xl my-gradient-text">
                Our works
            </h1>

            <main className={`relative z-20 w-full mt-8 md:flex md:items-center xl:mt-12 `}>
                <div className={`absolute w-full ${language ? "bg-gradient-to-l":"bg-gradient-to-r"} from-cyan-600 via-blue-800 to-indigo-900 -z-10 md:h-96 rounded-2xl`} />

                <div className={`w-full p-6 bg-blue-600 md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly ${language ? "flex-row-reverse text-right" : "flex-row text-left"}`}>
                    <img
                        className="h-24 w-24 md:mx-6 rounded-full object-cover shadow-md md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl"
                        src={active?.source || ""} alt="client photo" />

                    <div className="mt-2 md:mx-6">
                        <div>
                            <p className="text-xl font-medium tracking-tight text-white">{active?.title}</p>
                            <p className="text-blue-200 ">{active?.client}</p>
                        </div>

                        <p className="mt-4 text-lg leading-relaxed text-white md:text-xl">{active?.description}</p>

                        <div className={`flex items-center justify-between mt-6 ${language ? "md:justify-end" : "md:justify-start"}`}>
                            <button onClick={() => handleChangeActive(false)} title="left arrow" className="p-2 text-white transition-colors duration-300 border rounded-full rtl:-scale-x-100 hover:bg-blue-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>

                            <button onClick={() => handleChangeActive(true)} title="right arrow" className="p-2 text-white transition-colors duration-300 border rounded-full rtl:-scale-x-100 md:mx-6 hover:bg-blue-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Works
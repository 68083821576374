import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  bg: boolean
}

const GlassCard = ({ children, bg }: Props) => {
  return (
    <div className={`glass w-full rounded-xl shadow-xl p-5 ${!bg ? "bg-white/70" : "bg-gray-900/70"}`}>{children}</div>
  )
}

export default GlassCard
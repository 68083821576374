export const fetchData = async () => {
  try {
    const myHeaders = new Headers();

    const formdata = new FormData();
    formdata.append("key", "c292ce2254534270064d2a25f100dfc154d094b3");

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    // const response = await fetch("http://localhost/touch/api.php", requestOptions);
    const response = await fetch("https://admin.touch.sa/api.php", requestOptions);
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

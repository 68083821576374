import { useEffect, useRef, useState } from 'react'
import { animateScroll } from 'react-scroll'
import { useData } from '../components/DataProvider'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { slideLeft, slideRight } from '../components/slide'
import Footer from '../components/Footer'
import Contact from '../components/Contact'
import ServicesSection from '../components/Services'
import MissionAndVision from '../components/MissionAndVision'
import Hero from '../components/Hero'
import Header from '../components/Header'
import Member from '../components/Member'

const AboutPage = () => {

    const [Bg, setBg] = useState(true)
    const [scroll, setScroll] = useState(0)
    const [clientHover] = useState<boolean>(false)

    const divRef = useRef<HTMLDivElement>(null);

    const { language, info, team } = useData();

    const handleScroll = () => {
        if (divRef.current) {
            const scrollTop = divRef.current.scrollTop;
            setBg((scrollTop > 700 && scrollTop < 800) || (scrollTop > 2000 && scrollTop < 2300 ? true : false));
            setScroll(scrollTop)
            return
        }
    }


    useEffect(() => {
        let timer: any = null;

        function handleScroll() {
            if (timer !== null) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                animateScroll.scrollTo(window.pageYOffset);
            }, 200);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const text = language ?
        [
            "أفضل تجربة عرض",
            "لأفضل تجربة مؤتمرات",
            "لأفضل إدارة للحدث",
        ] : [
            "for best exhibition experience",
            "for best conferences experience",
            "for best event management",
        ]


    return (
        <main>
            <div
                ref={divRef}
                onScroll={handleScroll}
                className="fixed h-screen top-0 bottom-0 right-0 left-0 transition-all duration-200 scroll-smooth w-screen scrollbar-hide overflow-y-scroll overflow-x-hidden md:snap-y md:snap-mandatory overflow-scroll z-20 bg-transparent">

                <Header Bg={Bg} scroll={scroll} />

                <Hero
                    texts={text}
                    title={info?.title}
                />


                <MissionAndVision Bg={Bg} />


                <section className='snap-center md:h-screen top-0' id=''>
                    <ServicesSection Bg={Bg} />
                </section>

                <section className='snap-center h-screen top-0' id=''>
                    <div className={`w-screen h-screen flex items-center transition-all duration-1000 transform-gpu  ${Bg ? "bg-gray-100 text-white" : "bg-gray-100 text-black"}`}>
                        <div
                            id='teamSlider'
                            className="w-full flex space-x-10 overflow-x-scroll hide-scrollbar pl-40 pr-5 py-20 scroll-smooth transition-all"
                        >
                            {
                                team.length > 0 && team?.map((member) => (
                                    <Member
                                        key={member?.id}
                                        id={member?.id}
                                        image={member?.image!}
                                        name={member?.name!}
                                        role={member?.role!}
                                        social={member?.social!}
                                    />
                                ))
                            }
                        </div>
                        <div
                            className={`w-full absolute px-20 transform transition-all duration-300 hidden md:flex justify-between z-40 ${clientHover ? " md:pacity-100 " : " opacity-100 "}`}>
                            <ChevronLeftIcon onClick={() => slideLeft("teamSlider")} className='w-12 p-2 rounded-full bg-white shadow-xl text-gray-800 ' />
                            <ChevronRightIcon onClick={() => slideRight("teamSlider")} className='w-12 p-2 rounded-full bg-white shadow-xl text-gray-800 ' />
                        </div>
                    </div>
                </section>

                <section className='snap-center h-auto top-0'>
                    <Contact />
                </section>

                <section className='snap-center h-auto top-0'>
                    <div className={`w-screen h-auto flex items-center transition-all duration-1000 transform-gpu group ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <Footer />
                    </div>
                </section>

            </div>
        </main>
    )
}

export default AboutPage
import { motion } from 'framer-motion'
import Video from './Video'
import tv from "../assets/tv.png";
// import { Data } from '../context/infoContext'

type Props = {
    source: string;
}

const Tv = ({source}: Props) => {

    // const { info } = Data();
    

    return (
    //    <>
    //    {
    //     info?.latestWork ? (
            <div className='w-full md:h-screen flex flex-col space-y-10 justify-center items-center'>
            <div className="w-full flex flex-col items-center justify-start">
                {/* tv frame */}
                <motion.div
                    initial={{ opacity: 0, scale: 2.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1.5 }}
                    viewport={{ once: true }}
                    className='w-full max-w-6xl flex flex-col items-center justify-center relative z-30'>
                    <img 
                        src={tv}
                        alt='tv img'
                        width={100}
                        height={100}
                        className='absolute w-screen scale-[1.1] md:scale-100 h-[] md:w-[50rem] md:h-[27.85rem] lg:w-[75rem] lg:h-[40rem]'
                    />
                    <div className="relative w-screen px-4 md:px-0 h-[] md:w-[42rem] md:h-[26rem] lg:w-[60.4rem] lg:h-[38rem] -translate-y-[1.0rem] md:-translate-y-[1.85rem] lg:-translate-y-[2.7rem] ">
                    <Video controlStyle="md:-translate-y-[1.5rem] lg:-translate-y-[2.5rem]" video={source} />
                    </div>
                </motion.div>
                
            </div>
        </div>
    //     ) :
    //     (
    //         <>
    //         </>
    //     )
    //    }
    //    </>
    )
}

export default Tv



// old component

// import React from 'react'
// import HeaderVideo from './HeaderVideo'
// import { motion } from 'framer-motion'
// import { Data } from '../context/infoContext'


// const OurLatestWork = () => {

//     const { info } = Data();
    

//     return (
//        <>
//        {
//         info?.latestWork ? (
//             <div className='w-full md:h-screen flex flex-col space-y-10 justify-center items-center'>
//             {/* <Title title={arabic ? "شاهد أحدث أعمالنا" : "Watch our latest Work"} /> */}
//             <div className="w-full flex flex-col items-center justify-start">
//                 {/* tv frame */}
//                 <motion.div
//                     initial={{ opacity: 0, scale: 2.5 }}
//                     whileInView={{ opacity: 1, scale: 1 }}
//                     transition={{ duration: 0.5 }}
//                     viewport={{ once: true }}
//                     className='w-full md:w-10/12 xl:w-7/12 border-8 border-black shadow-2xl relative z-30'>
//                     <HeaderVideo video={info?.latestWork} />
//                 </motion.div>
//                 <motion.div
//                     initial={{ opacity: 0, y: 200 }}
//                     whileInView={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5, delay: 1 }}
//                     viewport={{ once: true }}
//                     className='relative hidden lg:flex z-10  flex-row justify-center' >
//                     <div className='z-20 h-[100px] -skew-x-12 w-10 bg-gray-100 dark:bg-gray-900 translate-x-4' />
//                     <div className=' z-10 h-[100px] w-20 bg-black/95' />
//                     <div className='z-20 h-[100px] skew-x-12 w-10 bg-gray-100 dark:bg-gray-900 -translate-x-4' />
//                 </motion.div>
//                 <motion.div
//                 initial={{ opacity: 0, y: 100 }}
//                 whileInView={{ opacity: 1, y: -2 }}
//                 transition={{ duration: 0.5, delay: 1.2 }}
//                 viewport={{ once: true }}
//                 className="w-72 hidden lg:flex items-center justify-center h-24 shadow-md bg-black/95 border-none rounded-[100%]">
//                     <div className="w-64 h-28 bg-gray-100 dark:bg-gray-900 translate-y-7 rounded-[100%]" />
//                 </motion.div>
//             </div>
//         </div>
//         ) :
//         (
//             <>
//             </>
//         )
//        }
//        </>
//     )
// }

// export default OurLatestWork
import { useData } from './DataProvider'
import { navigations } from '../store'

type Props = {
    status: boolean
}
const Navigation = ({ status }: Props) => {

    const { language } = useData();

    const navs = language ? navigations.arabic : navigations.english;
    return (
        <div
            className={`bg-black text-white transition-all duration-500 fixed  w-screen h-screen top-0 bottom-0 right-0 left-0 flex justify-center pt-20 md:pt-0 md:items-center ${status ? "opacity-100 z-40" : "opacity-0 z-0"}`}>
            <div className={`w-full flex flex-col max-w-7xl mx-auto p-7 pt-10 space-y-5 text-2xl ${language ? "items-end" : "items-start"}`}>
                {
                    navs?.map((nav, index) => (
                        <a key={index} href={nav.link || "/"} className="overflow-hidden w-auto flex flex-col space-y-3 md:max-w-[200px] group" >
                            <span>{nav?.title}</span>
                            <div className={`h-[1px] transition-all duration-500 ${language ? "translate-x-full" : "-translate-x-full"} group-hover:-translate-x-0 w-full bg-white`} />
                        </a>
                    ))
                }
            </div>
        </div>
    )
}

export default Navigation


import Header from '../components/Header';
import Hero from '../components/Hero';
import Works from '../components/Works';
import Contact from '../components/Contact';

const WorksPage = () => {
  return (
    <section>
            <div
                className="fixed h-screen top-0 bottom-0 right-0 left-0 transition-all duration-200 scroll-smooth w-screen scrollbar-hide overflow-y-scroll overflow-x-hidden snap-y snap-mandatory overflow-scroll z-20 bg-transparent">
                <Header scroll={0} />

                <Hero />

                <section className='snap-start bg-transparent'>
                    <div className="h-screen bg-transparent flex flex-col justify-center relative  items-center md:items-start md:text-left max-w-7xl md:px-10 mx-auto ">
                        <Works />
                    </div>
                </section>
                
                <section className='snap-start bg-transparent'>
                    <div className={`w-screen h-auto flex items-center transition-all duration-1000 transform-gpu group bg-transparent`}>
                    <Contact />
                </div>
                </section>

               
            </div>
        </section>
  )
}

export default WorksPage
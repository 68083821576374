import React from 'react'
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { useData } from './DataProvider';

type Props = {
  loop?: boolean;
  delaySpeed?: number;
  words: string[];
}

const Typing = ({ loop, delaySpeed, words }: Props) => {
  const { language } = useData();

  const [text, count] = useTypewriter({
    words: words,
    loop: loop || true,
    delaySpeed: delaySpeed || 2000,
  });

  return (
    <>
      {text}
     {!language && <Cursor cursorColor="#ccc" />}
    </>
  )
}

export default Typing
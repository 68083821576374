import { useState, useEffect } from 'react';
import { useData } from './DataProvider';

type Props = {
    scroll: number
}

const Clients = ({ scroll }: Props) => {
    const [slidePosition, setSlidePosition] = useState<number>(0);
    const [scrollTracker, setScrollTracker] = useState<number>(0);
    const [firstRow, setFirstRow] = useState<string[]>([]);
    const [secondRow, setSecondRow] = useState<string[]>([]);
    const [thirdRow, setThirdRow] = useState<string[]>([]);

    const { clients } = useData();


    useEffect(() => {

        console.log(clients)

        const firstThirdIndex = Math.ceil(clients.length / 3);
        const secondThirdIndex = Math.ceil((clients.length / 3) * 2);

        setFirstRow([...clients.slice(0, firstThirdIndex), ...clients.slice(0, firstThirdIndex), ...clients.slice(0, firstThirdIndex)]);
        setSecondRow([...clients.slice(firstThirdIndex, secondThirdIndex), ...clients.slice(firstThirdIndex, secondThirdIndex), ...clients.slice(firstThirdIndex, secondThirdIndex)]);
        setThirdRow([...clients.slice(secondThirdIndex), ...clients.slice(secondThirdIndex), ...clients.slice(secondThirdIndex)]);

    }, [clients]);

    useEffect(() => {
        if (scroll > 3200 && scroll < 4400) {
            // lets  sets the dirction by comparing the scroll stage and scroll tracker
            setSlidePosition(scroll > scrollTracker ? slidePosition + 4 : slidePosition - 4)
            setScrollTracker(scroll)
        }
    }, [scroll])

    return (
        <div className='flex flex-col space-y-1 h-full justify-center'>
            <div className='flex flex-row justify-center w-screen overflow-x-hidden h-44 '>
                {firstRow?.map((client, index) => (
                    <img
                        height={100}
                        width={200}
                        alt=""
                        key={index}
                        src={client}
                        className='w-28 md:w-40 h-auto '
                        style={{
                            transform: `translateX(${slidePosition}px)`
                        }}
                    />
                ))}
            </div>
            <div className='flex flex-row justify-center w-screen overflow-x-scroll h-44 hide-scrollbar'>
                {secondRow?.map((client, index) => (
                    <img
                        height={100}
                        width={200}
                        alt=""
                        key={index}
                        src={client}
                        className='w-28 md:w-40 h-auto '
                        style={{
                            transform: `translateX(-${slidePosition}px)`
                        }}
                    />
                ))}
            </div>
            <div className='flex flex-row justify-center w-screen overflow-x-scroll h-44 hide-scrollbar'>
                {thirdRow?.map((client, index) => (
                    <img
                        height={100}
                        width={200}
                        alt=""
                        key={index}
                        src={client}
                        className='w-28 md:w-40 h-auto '
                        style={{
                            transform: `translateX(${slidePosition}px)`
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Clients;

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import './App.css';
import ScrollToTop from "./components/ScrollToTop";
import { DataProvider } from "./components/DataProvider";
import Index from "./screens";
import About from "./screens/about";
import ServicesPage from "./screens/services";
import ContactPage from "./screens/contact";
import WorksPage from "./screens/works";

function App() {
  
  const location = useLocation();

  return (
    <div className="App">
      <DataProvider>
        <ScrollToTop />
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/works" element={<WorksPage />} />
        </Routes>
      </DataProvider>
    </div>
  );
}

export default App;

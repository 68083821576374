import Tilt from './Tilt'
import GlassCard from './GlassCard'
import Typing from './Typing'
import { useData } from './DataProvider'

type Props = {
Bg: boolean
}

const MissionAndVision = ({Bg}: Props) => {

    const { language, info } = useData();

    const text = language ?
    [
        "أفضل تجربة عرض",
        "لأفضل تجربة مؤتمرات",
        "لأفضل إدارة للحدث",
    ] : [
        "for best exhibition experience",
        "for best conferences experience",
        "for best event management",
    ]

    
    const axes = language ? ["أمة طموحة", "اقتصاد مزدهر", "مجتمع نابض بالحياة"] : ["An ambitious nation", "Thriving economy", "Vibrant society"];

    const slogan = language ? ['"نصنعك تحتفل"'] : ['"We create you celebrate"'];

    
    return (
        <section className='snap-center md:h-screen top-0' id=''>
            <div className={`w-screen md:h-screen transition-all duration-1000 transform-gpu ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                <div className={`md:h-screen flex flex-col space-y-5 md:space-y-0 md:space-x-10 justify-center items-center relative ${language ? "md:text-right md:flex-row-reverse md:space-x-reverse" : "md:text-left md:flex-row"} max-w-7xl p-5 mx-auto`}>
                    <div className="w-full max-w-xl relative z-10">
                        <Tilt>
                            <GlassCard bg={Bg}>
                                <div className='w-full flex flex-col  space-y-10 relative  justify-center max-w-lg'>
                                    <div className={`uppercase font-normal text-lg flex items-center space-x-3 ${language ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                        <div className={`h-[2px] w-7 ${Bg ? "bg-white" : "bg-black"}`} /> <h1>{language ? "مهمتنا" : "Our Mission"}</h1>
                                    </div>
                                    <h1 className='text-4xl font-bold max-w-xl  my-gradient-text'>
                                        {info.title} &nbsp;
                                        <Typing words={text} />
                                    </h1>

                                    <p className='max-w-lg'>{info?.mission}
                                        &nbsp;<b className='my-gradient-text'>
                                            <Typing words={slogan} />
                                        </b>
                                    </p>
                                </div>
                            </GlassCard>
                        </Tilt>
                    </div>
                    <div className="w-full max-w-xl relative z-10">
                        <Tilt>
                            <GlassCard bg={Bg}>
                                <div className='w-full flex flex-col  space-y-10 relative  justify-center max-w-lg'>
                                    <div className={`uppercase font-normal text-lg flex items-center space-x-3 ${language ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                        <div className={`h-[2px] w-7 ${Bg ? "bg-white" : "bg-black"}`} /> <h1>{language ? "رؤيتنا" : "Our Vision"}</h1>
                                    </div>
                                    <h1 className='text-4xl font-bold max-w-xl  my-gradient-text'>
                                        {info.title} &nbsp;
                                        <Typing words={text} />
                                    </h1>

                                    <p className='max-w-lg'>{info.vision}<br />
                                        <b className='text-2xl font-bold max-w-xl  my-gradient-text'>
                                            <Typing words={axes} />
                                        </b></p>
                                </div>
                            </GlassCard>
                        </Tilt>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default MissionAndVision
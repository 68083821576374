import Tilt from './Tilt';

type Props = {
    image: string;
    title?: string;
    text?: string;
}

const Card = ({ image, title, text }: Props) => {

    return (
        <Tilt>
            <div
                className='w-full h-[40vh] min-w-[25rem] md:h-[350px] md:w-[35rem] max-w-lg rounded-xl shadow-2xl overflow-hidden magnifying relative z-10 group'>
                <img
                    src={image}
                    width={100}
                    height={100}
                    className="w-full object-contasiner object-cover h-full group-hover:scale-125 transition duration-500" alt="" />
                {/* content */}
                {text &&
                    title &&
                    <div className="absolute top-0 bottom-0 right-0 left-0 w-full h-full bg-black/80 translate-y-full transition duration-500 group-hover:translate-y-0 flex flex-col justify-end text-white" >
                        {/* title */}
                        <div className="w-full p-5 flex flex-col space-y-3">
                            <span className="capitalize magnifying">{title}</span>
                            <p className="text-base">{text}</p>
                        </div>
                    </div>}
            </div>
        </Tilt>
    )
}

export default Card
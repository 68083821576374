import React from 'react'
import Header from '../components/Header'
import Contact from '../components/Contact'

const ContactPage = () => {
    return (
        <section>
            <div className="fixed h-screen top-0 bottom-0 right-0 left-0 transition-all duration-200 scroll-smooth w-screen scrollbar-hide overflow-y-scroll overflow-x-hidden snap-y snap-mandatory overflow-scroll z-20 bg-transparent">
                <Header scroll={0} />
                <div className={`w-screen h-auto flex items-center transition-all duration-1000 transform-gpu group bg-transparent`}>
                    <Contact />
                </div>
            </div>
        </section>
    )
}

export default ContactPage
import React, { useEffect, useRef, useState } from 'react'
import { animateScroll } from 'react-scroll';
import { useData } from '../components/DataProvider';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Typing from '../components/Typing';
import ServicesSection from '../components/Services';
import MissionAndVision from '../components/MissionAndVision';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const ServicesPage = () => {
    const [Bg, setBg] = useState(true)
    const [scroll, setScroll] = useState(0)

    const divRef = useRef<HTMLDivElement>(null);

    const { language } = useData();


    const handleScroll = () => {
        if (divRef.current) {
            const scrollTop = divRef.current.scrollTop;
            setBg(((scrollTop > 700 && scrollTop < 800) || scrollTop > 2000) && (scrollTop < 2300 ? true : false));
            setScroll(scrollTop)
            return
        }
    }


    useEffect(() => {
        let timer: any = null;

        function handleScroll() {
            if (timer !== null) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                animateScroll.scrollTo(window.pageYOffset);
            }, 200);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const text = language ?
        [
            "الخدمات التي نقدمها",
            "الذي نفعله",
            "خبرتنا"
        ] : [
            "Services We Offer",
            "What We Do",
            "Our Expertise",
        ];

    const infoWords = language ?
        [
            "اكتشف كيف يمكن لخدماتنا أن تأخذ عملك إلى المستوى التالي ",
            "شاهد كيف يمكن أن تساعد مجموعتنا الشاملة من الخدمات في تنمية أعمالك ",
            "تعمق في الطرق التي يمكن أن تساعد بها خدماتنا عملك على تحقيق النجاح "
        ] : [
            " Discover how our services can take your business to the next level.",
            " See how our comprehensive suite of services can help grow your business.",
            " Take a deeper dive into the ways our services can help your business achieve success."
        ]
    return (
        <section>
            <div
                ref={divRef}
                onScroll={handleScroll}
                className="fixed h-screen top-0 bottom-0 right-0 left-0 transition-all duration-200 scroll-smooth w-screen scrollbar-hide overflow-y-scroll overflow-x-hidden snap-y snap-mandatory overflow-scroll z-20 bg-transparent">

                <Header Bg={Bg} scroll={scroll} />

                <Hero texts={text} />

                <section className='snap-center h-screen top-0' >
                    <div className={`w-screen h-screen transition-all duration-1000 transform-gpu bg-black/25 text-white `}>
                        <div className={`h-screen flex  -space-x-10 justify-center items-center relative ${language ? "md:text-right justify-end space-x-reverse" : "md:text-left flex-row justify-start"} max-w-7xl px-10 mx-auto`}>
                            <div className={`w-full flex flex-col  space-y-10 relative  justify-center ${language ? "items-end" : "items-start"}`}>
                                <div className={`uppercase font-normal text-lg flex items-center space-x-3 ${language ? "flex-row-reverse space-x-reverse" : "flex-row"}`}>
                                    <div className={`h-[2px] w-7 bg-white`} />
                                    <h1>{language ? "الذي نفعله؟" : "What We Do?"}</h1>
                                </div>
                                <h1 className='text-3xl md:text-5xl font-bold max-w-xl my-gradient-text'>
                                    <Typing words={text} />
                                </h1>

                                <p className='max-w-xl'>{language ? "فريقنا مكرس لتقديم خدمات عالية الجودة تلبي الاحتياجات والأهداف الفريدة لعملائنا. من تطوير وتصميم الويب إلى التسويق الرقمي والعلامات التجارية ، نقدم مجموعة واسعة من الحلول المصممة لمساعدة الشركات على الازدهار في المشهد الرقمي المتطور باستمرار. سواء كنت شركة ناشئة أو شركة راسخة ، فنحن نعمل معك عن كثب لفهم رؤيتك وتحديد الفرص وتطوير استراتيجيات مخصصة تؤدي إلى النتائج" : "Our team is dedicated to providing high-quality services that meet the unique needs and goals of our clients. From web development and design to digital marketing and branding, we offer a wide range of solutions that are tailored to help businesses thrive in today&apos;s ever-evolving digital landscape. Whether you&apos;re a startup or a well-established company, we work closely with you to understand your vision, identify opportunities, and develop custom strategies that drive results"}<b className='my-gradient-text'><Typing words={infoWords} /></b></p>
                            </div>

                        </div>
                    </div>
                </section>

                <section className='snap-center h-screen top-0' id=''>
                    <ServicesSection Bg={Bg} />
                </section>


                <MissionAndVision Bg={Bg} />

                <section className='snap-end h-auto top-0' id=''>
                    <div className={`w-screen h-auto flex items-center transition-all duration-1000 transform-gpu group bg-transparent`}>
                        <Contact />
                    </div>
                </section>



                <section className='snap-end h-auto top-0' id=''>
                    <div className={`w-screen h-auto flex items-center transition-all duration-1000 transform-gpu group ${Bg ? "bg-black text-white" : "bg-white text-black"}`}>
                        <Footer />
                    </div>
                </section>

            </div>
        </section>
    )
}

export default ServicesPage